<template>
  <div class="proposal__wrap">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs__wrap">
      <el-breadcrumb-item :to="{ path: '/agent' }">代理管理</el-breadcrumb-item>
      <el-breadcrumb-item>交易明细列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="section shadow filter__wrap">
      <div>
        <el-radio-group v-model="table.params.type" @change="handleTabs">
          <el-radio-button :label="3">收益明细</el-radio-button>
          <el-radio-button :label="2">提现明细</el-radio-button>
        </el-radio-group>
      </div>
      <el-form ref="elFormDom" inline :model="table.params">
        <el-form-item label="" prop="order_no">
          <el-input clearable v-model="table.params.order_no" placeholder="请输入流水号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getTable">查询 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="table.params.type === 3 ? field : field2"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:amount="{row}">
          <span>{{row.amount}}元</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" icon="el-icon-delete" class="delBtn" @click="handleDelete(row)">删除</el-button>
        </template>
      </VTable>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'SalesmanList',
  mixins:[mixinTable],
  components: {
    VTable,
  },
  data() {
    return {
      field: [
        { name: "amount", label: "收益金额(元)", hidden: false },
        { name: "order_no", label: "收益流水", hidden: false },
        { name: "company_name", label: "收益来源", hidden: false },
        { name: "create_time", label: "收益时间", hidden: false },
      ],
      field2: [
        { name: "amount", label: "提现金额(元)", hidden: false },
        { name: "order_no", label: "提现流水", hidden: false },
        { name: "create_time", label: "到账时间", hidden: false },
      ],
      table: {
        loading: false,
        params: {
          type: 3,
          order_no: '',
          agent_id: sessionStorage.getItem('agentId'),
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/order/list', { params: this.table.params }).then(res => {
        if(res.code === 1) {
           this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 删除
    handleDelete(row) {
      this.$confirm("请确认删除操作?", "提示", {
        type: "warning",
        showCancelButton: true,
      }).then(() => {
        this.$http.post('/admin/agent/del', {id: row.id}).then(res => {
          if(res.code === 1) {
            this.$message.success("删除成功");
            this.getTable();
          } else {
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {});
    },
  }
}
</script>

<style scoped lang="scss">
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }

    .crumbs__wrap {
      margin-bottom: 20px;
    }
  }
</style>